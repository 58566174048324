import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import Spinner from "src/components/Spinner";
import { useUserAddresses } from "src/hooks/account";
import AddressForm from "./AddressForm";

export default function AddressChooser({
  userId,
  dialogTitleText = "Select address",
  open = false,
  onSelected = (address) => {},
  onClose,
}) {
  const [showAddForm, setShowAddForm] = useState(false);
  const userAddresses = useUserAddresses(userId);
  if (!open) {
    return <></>;
  }

  if (userAddresses.isLoading) {
    return <Spinner />;
  }

  const handleClose = () => {
    setShowAddForm(false);
    onClose();
  };

  const handleSelected = async (address) => {
    await onSelected(address);
    setShowAddForm(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} scroll="body">
      <DialogTitle>
        {showAddForm ? "Add new address" : dialogTitleText}
      </DialogTitle>
      {userAddresses.isLoading ? (
        <Spinner />
      ) : (
        <DialogContent>
          {!showAddForm && (
            <Stack
              direction="row"
              flexWrap="wrap"
              justifyContent="center"
              sx={{ gap: 1, flexShrink: 1 }}
            >
              {userAddresses.data.map((x) => (
                <Card
                  key={x.id}
                  variant="outlined"
                  sx={{ minWidth: "200px", maxWidth: "200px", height: "150px" }}
                >
                  <CardActionArea
                    sx={{ height: "100%" }}
                    onClick={() => handleSelected(x)}
                  >
                    <CardContent>
                      <Typography variant="h6">{x.name}</Typography>
                      <Typography variant="body2">{x.company}</Typography>
                      <Typography variant="body2">{x.street1}</Typography>
                      <Typography variant="body2">{x.street2}</Typography>
                      <Typography variant="body2">
                        {x.city}, {x.state} {x.zip}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              ))}
              <Card
                variant="outlined"
                sx={{ minWidth: "200px", maxWidth: "200px", height: "150px" }}
              >
                <CardActionArea
                  onClick={() => setShowAddForm(true)}
                  sx={{ height: "100%" }}
                >
                  <CardContent sx={{ textAlign: "center" }}>
                    <Typography
                      sx={{
                        color: "#8CAF98",
                        fontFamily: "karla",
                      }}
                    >
                      Add new address
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Stack>
          )}
          {showAddForm && (
            <AddressForm
              onCancel={() => setShowAddForm(false)}
              onSave={handleSelected}
            />
          )}
        </DialogContent>
      )}
      <DialogActions>
        {!showAddForm && <Button onClick={handleClose}>Cancel</Button>}
      </DialogActions>
    </Dialog>
  );
}
