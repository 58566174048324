import { useState } from "react";
import ProductCard from "src/pages/shop/ProductCard";
import AddressChooser from "src/pages/account/AddressChooser";
import AddressForm from "src/pages/account/AddressForm";
import { Button, Stack, Container, Card, CardContent } from "@mui/material";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { UserId } from "src/slices/authSlice";
import Iconify from "src/components/Iconify";
import { useRedeemGiftInvitation } from "src/hooks/giftInvitation";

export const RedeemStep = ({ tributeId, onNext, giftMode }) => {
  const [addressChooserOpen, setAddressChooserOpen] = useState();
  const [selectedAddress, setSelectedAddress] = useState(
    giftMode.defaultAddress
  );

  const products = giftMode.giftBundleItems
    .filter((x) => x.childProductCategory === "PhysicalGood")
    .map((y) => y.childProduct);

  const userId = useSelector(UserId);
  const location = useLocation();
  const navigate = useNavigate();
  const redeemGiftInvitation = useRedeemGiftInvitation();

  return (
    <Container disableGutters={true}>
      <AddressChooser
        userId={userId}
        dialogTitleText="Select shipping address"
        open={addressChooserOpen}
        onClose={() => setAddressChooserOpen(false)}
        onSelected={async (address) => {
          setSelectedAddress(address);
          setAddressChooserOpen(false);
        }}
      />
      <Stack spacing={2} mt={1}>
        <Card
          sx={{
            backgroundColor: "unset",
            maxWidth: "600px",
            width: "100%",
          }}
        >
          <CardContent sx={{ m: 0, p: 0, "&:last-child": { pb: 0 } }}>
            {selectedAddress ? (
              <Button
                display="block"
                color={selectedAddress ? "primary" : "warning"}
                variant="text"
                size="large"
                startIcon={<Iconify icon="mdi:location" />}
                sx={{ textTransform: "unset" }}
                onClick={() => setAddressChooserOpen(true)}
              >
                {selectedAddress
                  ? `Deliver to ${selectedAddress.name} - ${selectedAddress.city} ${selectedAddress.zip}`
                  : "Click to Set Shipping Address"}
              </Button>
            ) : (
              <AddressForm
                formTitle="Enter Shipping Address:"
                onSave={async (address) => {
                  setSelectedAddress(address);
                }}
                buttonText="Save Address and Continue"
              />
            )}
          </CardContent>
        </Card>
        {products &&
          products.map((product) => (
            <ProductCard
              key={product.id}
              detailsRoute={`/shop/${product.id}`}
              inNewTab={true}
              isDisabled={!selectedAddress}
              productWithLine={product}
              onSelect={async () => {
                const searchParams = new URLSearchParams(location.search);
                const verifyToken = searchParams.get("verifyToken");

                await redeemGiftInvitation.mutateAsync({
                  redeemedForPhysicalProductId: product.id,
                  redeemedForObituaryId: tributeId,
                  addressId: selectedAddress.id,
                  encodedToken: verifyToken,
                });

                navigate(`/obituaries/${tributeId}`);
                onNext();
              }}
              buttonText="Select"
              buttonIcon={"mdi:gift"}
            />
          ))}
      </Stack>
    </Container>
  );
};
