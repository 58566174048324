import { Button } from "@mui/material";
import { useWizardContext } from "src/features/CreateTributeWizard/WizardContext";
import { trackCreateWizardOpened } from "src/utils/trackingTags";

const CreateObituaryButton = ({
  memorialData = null,
  openWizardSource = "upload_tribute_button",
  isGift = null,
  func = () => {},
  children,
  ...props
}) => {
  const { openModal, openWizard, setGiftMode } = useWizardContext();

  return (
    <Button
      id="landing-register-button"
      {...props}
      onClick={() => {
        func();
        setGiftMode(isGift);
        trackCreateWizardOpened(openWizardSource);
        if (!memorialData) {
          openModal();
        } else {
          openModal(memorialData);
          openWizard(null);
        }
      }}
    >
      {children}
    </Button>
  );
};

export default CreateObituaryButton;
