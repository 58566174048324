import { useQuery, useMutation } from "react-query";
import { useQueryClient } from "react-query";
import api from "../services/api";
import { ObjectToQueryString } from "src/utils/uri";

const getGiftInvitation = async ({ verifyToken }) => {
  const { data } = await api.get(
    `/gift_invitation?${ObjectToQueryString({ verifyToken })}`
  );
  return data;
};

const redeemGiftInvitation = async (redeemModel) => {
  const { data } = await api.put(`/gift_invitation`, redeemModel);
  return data;
};

const resendGiftInvitation = async ({
  id,
  email,
  name,
  senderName,
  customMessage,
}) => {
  await api.post(`/gift_invitation/${id}/resend`, {
    email,
    name,
    senderName,
    customMessage,
  });
};

export const useLookupGiftInvitation = (verifyToken) => {
  return useQuery(
    ["lookupGiftInvitation", { verifyToken }],
    () => getGiftInvitation({ verifyToken }),
    { retry: false }
  );
};

export const useRedeemGiftInvitation = () => {
  const queryClient = useQueryClient();

  return useMutation(redeemGiftInvitation, {
    onSuccess: () => {
      return queryClient.invalidateQueries(["lookupGiftInvitation"]);
    },
  });
};

export const useResendGiftInvitation = () => {
  // const queryClient = useQueryClient();
  return useMutation(resendGiftInvitation, {
    onSuccess: () => {
      //TODO: What should be invalidated? Maybe orders
    },
  });
};
