import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
// material
import { Alert, Button, Stack, Typography, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// component
import { StateList } from "src/utils/stateList";
import { useAddAddress } from "src/hooks/account";
import { useSelector } from "react-redux";
import { useState } from "react";
import HookFormTextField from "src/components/HookFormTextField";
// action

// ----------------------------------------------------------------------
const AddressSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  street1: Yup.string().required("Address Line 1 is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  zip: Yup.string()
    .required("Zip is required")
    .matches(/^\d{5}(?:[-\s]\d{4})?$/, "Zip code is not valid"),
});

export default function AddressForm({
  address = {},
  onSave,
  formTitle,
  buttonText = "Save",
  onCancel,
}) {
  const addressMutation = useAddAddress();
  const { user } = useSelector((state) => state.auth);
  const [error, setError] = useState();

  const formMethods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(AddressSchema),
    defaultValues: {
      name: address.name || `${user.firstName} ${user.lastName}`,
      street1: address.street1 || "",
      street2: address.street2 || "",
      city: address.city || "",
      state: address.state || "",
      zip: address.zip || "",
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = formMethods;

  const onSubmit = async (values) => {
    setError();
    try {
      const result = await addressMutation.mutateAsync({
        id: user.id,
        formData: values,
      });
      await onSave(result);
    } catch (error) {
      setError(error);
    }
  };

  return (
    <Box>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={1}>
          <Typography variant="h5">{formTitle}</Typography>
          <HookFormTextField
            name="name"
            label="Name"
            fullWidth
            formMethods={formMethods}
          />
          <HookFormTextField
            name="street1"
            label="Address"
            fullWidth
            formMethods={formMethods}
          />
          <HookFormTextField
            name="street2"
            label="Apartment, suite, etc."
            fullWidth
            formMethods={formMethods}
          />
          <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
            <Stack direction="row" spacing={1}>
              <HookFormTextField
                name="city"
                label="City"
                fullWidth
                formMethods={formMethods}
              />
              <HookFormTextField
                name="state"
                label="State"
                formMethods={formMethods}
                select={true}
                options={StateList.map((x) => ({
                  value: x.abbreviation,
                  label: x.name,
                }))}
                sx={{ minWidth: { xs: "11ch", sm: "12ch", md: "15ch" } }}
              />
            </Stack>
            <HookFormTextField
              name="zip"
              label="Zip"
              formMethods={formMethods}
            />
          </Stack>
          {error && <Alert severity="error">{error}</Alert>}
          <Stack direction="row">
            {onCancel && (
              <Button fullWidth onClick={onCancel}>
                Cancel
              </Button>
            )}
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              {buttonText}
            </LoadingButton>
          </Stack>
        </Stack>
      </form>
    </Box>
  );
}
