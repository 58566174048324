import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import {
  trackClickedNoThanks,
  trackClickedUpgradeNow,
} from "src/utils/trackingTags";
const {
  Button,
  Stack,
  Typography,
  Container,
  CardActionArea,
  Box,
  Card,
} = require("@mui/material");

export const UpsellStep = ({ id, close, onNext }) => {
  const navigate = useNavigate();

  return (
    <Container disableGutters={true}>
      <Card
        sx={{
          flexGrow: 1,
          flexBasis: { xs: "initial", md: 0 },
          bgcolor: "unset",
        }}
      >
        <CardActionArea sx={{ height: "100%" }} component="div">
          <Stack sx={{ height: "100%", padding: 1 }}>
            <Box>
              <Typography
                sx={{
                  display: "inline",
                  fontSize: "1.75rem",
                  textAlign: "center",
                  color: "black",
                }}
              >
                Do you have more Tribute details to add?
              </Typography>
            </Box>
            <Typography variant="h8">Upgrades Include:</Typography>
            <ul style={{ paddingLeft: "20px" }}>
              <li>
                <Typography variant="body1" component="span">
                  Upload Additional Photos
                </Typography>
              </li>
              <li>
                <Typography variant="body1" component="span">
                  Unlimited Word Count
                </Typography>
              </li>
              <li>
                <Typography variant="body1" component="span">
                  And so much more!
                </Typography>
              </li>
            </ul>
            <Box sx={{ flexGrow: 1 }} />
            <LoadingButton
              fullWidth
              variant="contained"
              onClick={() => {
                trackClickedUpgradeNow();
                close();
                navigate(`/account/tribute/${id}/subscription`);
              }}
              sx={{ marginTop: 1, boxShadow: "unset" }}
            >
              Upgrade Now!
            </LoadingButton>
          </Stack>
        </CardActionArea>
      </Card>
      <Stack spacing={2} mt={3}>
        <Button
          variant="text"
          color="primary"
          onClick={() => {
            trackClickedNoThanks();
            onNext();
          }}
          sx={{
            "&:hover": {
              textDecoration: "underline",
              backgroundColor: "transparent",
            },
          }}
        >
          No Thanks
        </Button>
      </Stack>
    </Container>
  );
};
