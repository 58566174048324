import {
  Avatar,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import Iconify from "src/components/Iconify";
import { IsLoggedOn, UserId } from "src/slices/authSlice";
import { useUserUploads } from "src/hooks/account";
import { resolvePrimaryImageSrc } from "src/utils/imageLinks";
import { SupportsWebp } from "src/slices/appSlice";
import { LoadingButton } from "@mui/lab";
import LoginRequiredDialog from "../login/LoginRequiredDialog";
import { useAddToCart } from "src/hooks/order";
import { useSnackbar } from "notistack";
import Spinner from "src/components/Spinner";
import CreateObituaryButton from "src/components/CreateObituaryButton";
import { Stack } from "@mui/system";

const TributeList = ({ userId, onTributeSelect, onCreateNew }) => {
  const supportsWebp = useSelector(SupportsWebp);
  const tributesQuery = useUserUploads(userId, 0, "Published", -1);
  const tributes = tributesQuery.data?.entries || [];

  if (tributesQuery.isLoading) {
    return <Spinner />;
  }

  return (
    <List sx={{ width: "100%", maxWidth: 720, bgcolor: "background.paper" }}>
      {tributes.length === 0 && (
        <ListItem disablePadding>
          <Stack direction="column" spacing={1}>
            <Typography>
              You don't have any published tributes yet, to create a new tribute
              or publish one of your drafts click the button below:
            </Typography>
            <CreateObituaryButton
              variant="contained"
              color="primary"
              openWizardSource="add_to_cart_no_tributes_upload_button"
            >
              Upload Tribute
            </CreateObituaryButton>
          </Stack>
        </ListItem>
      )}
      {tributes.map((tribute) => (
        <ListItem key={tribute.id} disablePadding>
          <ListItemButton
            onClick={() => {
              onTributeSelect(tribute);
            }}
          >
            <ListItemAvatar>
              <Avatar
                src={resolvePrimaryImageSrc(
                  "obituary",
                  tribute.id,
                  tribute.primaryImageFileNamePrefix,
                  "md",
                  supportsWebp ? "webp" : "jpg"
                )}
              />
            </ListItemAvatar>
            <ListItemText
              primary={tribute.displayName || "Draft (name not entered yet)"}
              secondary={tribute.displayYears}
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

const AddToCartButton = ({ product, children, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const userId = useSelector(UserId);
  const isLoggedOn = useSelector(IsLoggedOn);
  const [dialogOpen, setDialogOpen] = useState(false);
  const addToCart = useAddToCart();

  const closeModal = () => {
    setDialogOpen(false);
  };

  const handleClick = async (obituaryId) => {
    if (!isLoggedOn || (product.requiresObituaryId && !obituaryId)) {
      setDialogOpen(true);
      return;
    }

    // if function gets to this point then user is logged on, has clicked add to cart
    // and we either have an obituaryId (if required) or we don't need an obituary id
    try {
      await addToCart.mutateAsync({
        obituaryId,
        productId: product.id,
      });
      enqueueSnackbar("Added to Cart", { variant: "success" });
      setDialogOpen(false);
    } catch (error) {
      enqueueSnackbar(`Failed to add to cart: ${error}`, { variant: "error" });
    }
  };

  return (
    <>
      {/* somehow we need to change the "openModel" event to 
            1. Check if they are logged on and give them the option to logon or Register
            2. Check if they are legged on and the item doesn't require an obituary - then add it to the cart
            3. If they are logged on and the item does require an obituary have them choose the obituary
      */}
      <LoadingButton
        {...props}
        loading={addToCart.isLoading}
        id="landing-register-button"
        onClick={() => handleClick()}
      >
        {children}
      </LoadingButton>
      {dialogOpen && (
        <LoginRequiredDialog
          open={true}
          onClose={closeModal}
          onAuthenticated={() => handleClick()} // if authentication is required we'll retry to handle the add to cart
        >
          <Dialog fullWidth open={dialogOpen} onClose={closeModal}>
            <DialogTitle sx={{ pr: 5 }}>
              This product is customized to work with one of your published
              tributes, please select the tirbute from below.
              <IconButton
                aria-label="close"
                onClick={closeModal}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <Iconify icon="eva:close-outline" width={20} height={20} />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <TributeList
                userId={userId}
                setDraftsLoaded={true}
                selectionMade={closeModal}
                onTributeSelect={(tribute) => handleClick(tribute.id)}
              />
            </DialogContent>
          </Dialog>
        </LoginRequiredDialog>
      )}
    </>
  );
};

export default AddToCartButton;
